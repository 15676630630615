.p__label {
    font-family: "Titillium Web";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 27px;
    color: $text-300;

    &.p__label--error {
        color: $danger-300;
    }

    &.p__label--success {
        color: $success-300;
    }
}
