@import "./Variables.scss";
@import "./Global.scss";
@import "../components/FaIcon/FaIcon.scss";
@import "../components/Heading/Heading.scss";
@import "../components/Input/Input.scss";
@import "../components/Label/Label.scss";
@import "../components/Link/Link.scss";
@import "../components/Spinner/Spinner.scss";

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b3afb3;
}

::-webkit-scrollbar-track {
  background: #d1d1d1;
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

  .login__container--accedi {
    background-color: $interaction-200;
  }

  .login__container--registrati {
    background-color: $text-300;
  }

  .login__container--ripristina-password {
    background-color: $text-300;
  }

  .login__container--cambia-password {
    background-color: $text-300;
  }

  .p__scroller--registrati {
    padding-right: 8px;
    margin-right: -12px; // margin + scroll width
  }
