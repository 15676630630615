@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap");

* {
    font-family: "Titillium Web", Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.text--center {
    text-align: center;
}

.text--left {
    text-align: left;
}

.text--right {
    text-align: right;
}

.display--flex {
    display: flex;

    .flex__item {
        flex-grow: 1;
    }
}
