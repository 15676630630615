.p__spinner {
    display: inline-block;
    position: relative;
    -webkit-animation: p__spinner__container-rotate 1568.23529412ms linear infinite;
    animation: p__spinner__container-rotate 1568.23529412ms linear infinite;

    &.p__spinner--size-sm {
        width: 20px;
        height: 20px;
    }

    &.p__spinner--size-md {
        width: 30px;
        height: 30px;
    }

    &.p__spinner--size-lg {
        width: 40px;
        height: 40px;
    }

    &.p__spinner--variant-primary {
        border-color: $text-300;
    }

    &.p__spinner--variant-secondary {
        border-color: $white;
    }

    &.p__spinner--variant-cta {
        border-color: $interaction-200;
    }
}

@-webkit-keyframes p__spinner__container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes p__spinner__container-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.p__spinner__layer {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.p__spinner .p__spinner__layer-1 {
    -webkit-animation: p__spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        p__spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: p__spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        p__spinner__layer-1-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.p__spinner .p__spinner__layer-2 {
    -webkit-animation: p__spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        p__spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: p__spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        p__spinner__layer-2-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.p__spinner .p__spinner__layer-3 {
    -webkit-animation: p__spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        p__spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: p__spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        p__spinner__layer-3-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.p__spinner .p__spinner__layer-4 {
    -webkit-animation: p__spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        p__spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: p__spinner__fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,
        p__spinner__layer-4-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@-webkit-keyframes p__spinner__fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }

    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }

    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }

    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }

    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }

    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes p__spinner__fill-unfill-rotate {
    12.5% {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    25% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    37.5% {
        -webkit-transform: rotate(405deg);
        transform: rotate(405deg);
    }

    50% {
        -webkit-transform: rotate(540deg);
        transform: rotate(540deg);
    }

    62.5% {
        -webkit-transform: rotate(675deg);
        transform: rotate(675deg);
    }

    75% {
        -webkit-transform: rotate(810deg);
        transform: rotate(810deg);
    }

    87.5% {
        -webkit-transform: rotate(945deg);
        transform: rotate(945deg);
    }

    to {
        -webkit-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@-webkit-keyframes p__spinner__layer-1-fade-in-out {
    from,
    25% {
        opacity: 0.99;
    }

    26%,
    89% {
        opacity: 0;
    }

    90%,
    100% {
        opacity: 0.99;
    }
}

@keyframes p__spinner__layer-1-fade-in-out {
    from,
    25% {
        opacity: 0.99;
    }

    26%,
    89% {
        opacity: 0;
    }

    90%,
    100% {
        opacity: 0.99;
    }
}

@-webkit-keyframes p__spinner__layer-2-fade-in-out {
    from,
    15% {
        opacity: 0;
    }

    25%,
    50% {
        opacity: 0.99;
    }

    51% {
        opacity: 0;
    }
}

@keyframes p__spinner__layer-2-fade-in-out {
    from,
    15% {
        opacity: 0;
    }

    25%,
    50% {
        opacity: 0.99;
    }

    51% {
        opacity: 0;
    }
}

@-webkit-keyframes p__spinner__layer-3-fade-in-out {
    from,
    40% {
        opacity: 0;
    }

    50%,
    75% {
        opacity: 0.99;
    }

    76% {
        opacity: 0;
    }
}

@keyframes p__spinner__layer-3-fade-in-out {
    from,
    40% {
        opacity: 0;
    }

    50%,
    75% {
        opacity: 0.99;
    }

    76% {
        opacity: 0;
    }
}

@-webkit-keyframes p__spinner__layer-4-fade-in-out {
    from,
    65% {
        opacity: 0;
    }

    75%,
    90% {
        opacity: 0.99;
    }

    100% {
        opacity: 0;
    }
}

@keyframes p__spinner__layer-4-fade-in-out {
    from,
    65% {
        opacity: 0;
    }

    75%,
    90% {
        opacity: 0.99;
    }

    100% {
        opacity: 0;
    }
}

.p__spinner__gap-patch {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 45%;
    width: 10%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}

.p__spinner__gap-patch .p__spinner__circle {
    width: 1000%;
    left: -450%;
}

.p__spinner__circle-clipper {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-color: inherit;
}

.p__spinner__circle-clipper .p__spinner__circle {
    width: 200%;
}

.p__spinner__circle {
    box-sizing: border-box;
    height: 100%;
    border-width: 4px;
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.p__spinner--small .p__spinner__circle {
    border-width: 3px;
}

.p__spinner--large .p__spinner__circle {
    border-width: 5px;
}

.p__spinner__left .p__spinner__circle {
    border-right-color: transparent !important;
    -webkit-transform: rotate(129deg);
    transform: rotate(129deg);
}

.p__spinner .p__spinner__left .p__spinner__circle {
    -webkit-animation: p__spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: p__spinner__left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.p__spinner__right .p__spinner__circle {
    left: -100%;
    border-left-color: transparent !important;
    -webkit-transform: rotate(-129deg);
    transform: rotate(-129deg);
}

.p__spinner .p__spinner__right .p__spinner__circle {
    -webkit-animation: p__spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation: p__spinner__right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

@-webkit-keyframes p__spinner__left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@keyframes p__spinner__left-spin {
    from {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    to {
        -webkit-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@-webkit-keyframes p__spinner__right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }

    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

@keyframes p__spinner__right-spin {
    from {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }

    50% {
        -webkit-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    to {
        -webkit-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}
