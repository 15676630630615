.p__inputContainer {
    display: flex;
    border-radius: 8px;
    border: solid 1px $neutral-500;
    background-color: $white;

    &.p__inputContainer--focused {
        border: solid 1px $interaction-300;
        outline: none;
        box-shadow: rgb(66 153 225 / 50%) 0px 0px 0px 3px;
    }

    &.p__inputContainer--success {
        border: solid 1px $success-300;
    }

    &.p__inputContainer--error {
        border: solid 1px $danger-300;
    }

    &.p__inputContainer--fullwidth {
        width: 100%;
        position: relative;

        .p__input {
            width: 100%;
        }
    }

    &.p__inputContainer--icon {
        &.p__inputContainer--icon-left {
            .p__inputIcon {
                margin-left: 12px;

                .p__faIcon {
                    top: 3px;
                    position: relative;
                }
            }

            .p__input {
                padding-left: 12px;
            }
        }

        &.p__inputContainer--icon-right {
            .p__inputIcon {
                margin-right: 12px;

                .p__faIcon {
                    top: 3px;
                    position: relative;
                }
            }

            .p__input {
                padding-right: 12px;
            }
        }
    }

    .p__input {
        position: relative;
        border: none;
        border-radius: 8px;
        font-family: "Titillium Web";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: $text-100;
        padding: 7px 16px;

        ::placeholder {
            color: $neutral-300;
            opacity: 1;
        }

        &:active,
        &:focus {
            outline: none;
            box-shadow: none!important;
        }
    }
}
