.p__link {
    font-family: "Titillium Web";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 21px;
    letter-spacing: 0.07px;
    color: $interaction-300;
    text-decoration: underline;

    &:active {
        color: $interaction-300;
    }
}
