.p__heading {
    font-family: "Titillium Web";
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 46px;
    color: $text-300;
    padding: 0;
    margin: 0;
}